import { DOMAIN_NIMBL } from '@constants/constants';

function getNprogressColor(domain: string) {
  if (domain === DOMAIN_NIMBL) {
    return '#69dbc6';
  }
  return '#4fbb66';
}

export default getNprogressColor;
